import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

import Grid from "../dotgrid/grid"
import Row from "../dotgrid/row"
import Cell from "../dotgrid/cell"
import Link from "../link"

const Container = styled.section`
  color: ${(props) => props.theme.colorWhite};
  background-color: ${(props) => props.theme.colorGreyDark};

  a {
    display: inline-block;
    margin-top: 2em;
  }

  @media ${(props) => props.theme.xsmallDown} {
    .-cell:not(:first-child) {
      margin-top: 0.6em;
    }
  }
`

const Usps = ({
  data: {
    acf: { usps },
  },
}) => {
  if (!usps || !usps.length) return null

  return (
    <Container>
      <Grid>
        <Row pullY={2} mq={{ xsmallDown: { pullY: 4 } }}>
          {usps.map((item, i) => (
            <Cell
              key={i}
              size={9}
              mq={{ xxxlargeDown: { size: 23 } }}
              className="styled-h1 -cell"
            >
              {item.title}

              {item.linkText && item.linkUrl && (
                <div className="styled-p-leading">
                  <Link to={item.linkUrl} className="styled-a">
                    {item.linkText}
                  </Link>
                </div>
              )}
            </Cell>
          ))}
        </Row>
      </Grid>
    </Container>
  )
}

Usps.propTypes = {
  data: PropTypes.object.isRequired,
}

export default Usps
