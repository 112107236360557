import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import MetaWp from "../components/meta-wp"
import Hero from "../components/hero"
import TextSections from "../components/text-sections"
import VideoCarousel from "../components/video-carousel"
import Blurbs from "../components/blurbs"
import Quote from "../components/page-product/quote"
import Highlights from "../components/page-product/highlights"
import Image from "../components/page-solution/image"
import Usps from "../components/page-solution/usps"
import AboutBlurbs from "../components/about-blurbs"
import Numbers from "../components/numbers"

const SolutionPage = ({ data, pageContext }) => {
  const {
    metaDefaults,
    metaWpGlobal,
    metaWpSolution,
    hideFooterSections,
    hero,
    usps,
    textSections,
    video,
    blurbs,
    solutionsNumbers,
    wp,
    siteMessage,
  } = data

  const {
    description,
    highlights,
    highlightedArticles,
    image,
    testimonialQuote,
    testimonialPersonName,
    testimonialPersonTitle,
    testimonialCompany,
    showVideoHighUpOnPage,
  } = wp.acf

  const { translations, menus } = pageContext

  hero.acf.description = description

  return (
    <Layout
      translations={translations}
      menus={menus}
      metaDefaults={metaDefaults}
      metaWpGlobal={metaWpGlobal}
      hideFooterSections={hideFooterSections.acf}
      siteMessage={siteMessage}
    >
      <MetaWp
        page={metaWpSolution}
        global={metaWpGlobal}
        defaults={metaDefaults}
      />

      <Hero data={hero} />

      <Usps data={usps} />

      {showVideoHighUpOnPage && <VideoCarousel data={video} />}

      <TextSections data={textSections} />

      <Highlights data={highlights} />

      <Numbers data={solutionsNumbers} />

      <Quote
        data={{
          text: testimonialQuote,
          firstname: testimonialPersonName,
          title: testimonialPersonTitle,
          company: testimonialCompany,
        }}
      />

      {highlightedArticles && (
        <AboutBlurbs data={{ acf: highlightedArticles }} showTitle={true} />
      )}

      {!showVideoHighUpOnPage && <VideoCarousel data={video} />}

      <Image data={image} />

      <Blurbs data={blurbs} />
    </Layout>
  )
}

export default SolutionPage

SolutionPage.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
}

export const pageQuery = graphql`
  query ($id: String!) {
    ...MetaDefaultsFragment
    ...MetaWpGlobalFragment
    ...MetaWpSolutionFragment
    ...SiteMessageFragment
    ...HideFooterSectionsSolutionFragment

    ...HeroSolutionFragment
    ...UspsSolutionFragment
    ...TextSectionsSolutionFragment
    ...VideoSolutionFragment
    ...BlurbsSolutionFragment
    ...SolutionsNumbersSolutionFragment

    wp: wordpressWpSolution(id: { eq: $id }) {
      acf {
        image: solution_image {
          localFile {
            childImageSharp {
              fluid(maxWidth: 5120) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        description: solution_description
        highlights: solution_highlights {
          text: solution_highlight_text
          text2: solution_highlight_text_2
        }
        highlightedArticles: highlighted_articles {
          title: highlighted_articles_title
          blurbs: highlighted_articles_resources {
            title: resource_title
            text: resource_excerpt_text
            link: resource_link {
              url
              title
              target
            }
            image: resource_excerpt_image {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 960) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            date: resource_date
            type: resource_type
          }
        }
        testimonialQuote: solution_testamonial_quote
        testimonialCompany: solution_testamonial_company
        testimonialPersonName: solution_testamonial_person_name
        testimonialPersonTitle: solution_testamonial_person_title
        showVideoHighUpOnPage: solution_show_video_high_up_on_page
      }
    }
  }
`
